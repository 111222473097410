<template>
    <div>
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <h2 class="content-header-title float-start mb-0">
              Festival Master
            </h2>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-2">
          <div class="col-12">
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb float-end">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">Festival</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <section id="nav-filled">
            <div class="row match-height">
                <div class="col-xl-12 col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- Nav tabs -->
                            <ul class="nav nav-tabs nav-fill setting" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link" id="table-tab-fill" data-bs-toggle="tab" href="#table-fill" role="tab" aria-controls="table-fill" aria-selected="true">Table</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link active" id="cal-tab-fill" data-bs-toggle="tab" href="#cal-fill" role="tab" aria-controls="cal-fill" aria-selected="false">Calendar</a>
                                </li>
                            </ul>

                            <!-- Tab panes -->
                            <div class="tab-content pt-1">
                                <div class="tab-pane" id="table-fill" role="tabpanel" aria-labelledby="table-tab-fill">
                                    <div class="row mb-1">
                                        <div class="d-flex col-12">
                                            <div class="float-start col-6">
                                            <div class="input-group input-group-merge w-50">
                                                <span class="input-group-text" id="basic-addon-search2"
                                                ><vue-feather type="search"></vue-feather></span>
                                                <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Search..."
                                                aria-label="Search..."
                                                aria-describedby="basic-addon-search2"
                                                v-model="search"
                                                @keyup="searchData"
                                                />
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <section id="ajax-datatable">
                                        <div class="row">
                                            <div class="col-12">
                                            <div class="card">
                                                <div class="card-datatable">
                                                <table
                                                    class="dt-column-search table table-responsive"
                                                >
                                                    <thead>
                                                    <tr>
                                                        <template v-for="head in header" :key="head">
                                                        <th>{{ head }}</th>
                                                        </template>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-show="list.length > 0" v-for="(li, index) in list" :key="li.id">
                                                        <td>{{ index + paginationData.from }}</td>
                                                        <td>{{ li.name }}</td>
                                                        <td>{{ li.date }}</td>
                                                        <td>{{ li.price }}</td>
                                                        <td>{{ li.created_at }}</td>
                                                        <td>{{ li.updated_at }}</td>
                                                        <td class="d-flex">
                                                        <div
                                                            class="d-flex justify-content-start align-items-center mb-75"
                                                        >
                                                            <span class="me-75">
                                                            <div
                                                                class="avatar bg-light-primary rounded"
                                                                @click="onClickItem(li.id)"
                                                            >
                                                                <div class="avatar-content">
                                                                <vue-feather type="edit" size="15"></vue-feather>
                                                                </div>
                                                            </div>
                                                            </span>
                                                        </div>
                                                        <div
                                                            class="d-flex justify-content-start align-items-center mb-75"
                                                        >
                                                            <span class="me-75">
                                                            <div
                                                                class="avatar bg-light-danger rounded"
                                                                @click="deleteDep(li.id)"
                                                            >
                                                                <div class="avatar-content">
                                                                <vue-feather type="trash-2" size="15"></vue-feather>
                                                                </div>
                                                            </div>
                                                            </span>
                                                        </div>
                                                        </td>
                                                    </tr>
                                                    <tr v-show="list.length === 0">
                                                        <td colspan="7" class="text-center">No Data Found</td>
                                                    </tr>
                                                    </tbody>
                                                    <tfoot>
                                                    <tr>
                                                        <template v-for="head in header" :key="head">
                                                        <th>{{ head }}</th>
                                                        </template>
                                                    </tr>
                                                    </tfoot>
                                                </table>
                                                <div class="row d-flex">
                                                    <div class="col-12 d-flex">
                                                    <div class="col-6 showing_entry">
                                                        Showing {{ paginationData.from }} to
                                                        {{ paginationData.to }} of
                                                        {{ paginationData.total }} entries
                                                    </div>
                                                    <div class="col-6">
                                                        <Pagination
                                                        :data="paginationData"
                                                        @pagination-change-page="getResults"
                                                        show-disabled="true"
                                                        limit="3"
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </section>
                                </div>
                                <div class="tab-pane active" id="cal-fill" role="tabpanel" aria-labelledby="cal-tab-fill">
                                    <div class="app-calendar border mb-0"> 
                                        <div class="row g-0 border">
                                            <!-- Sidebar -->
                                            <div class="col-2 border-right app-calendar-sidebar flex-grow-0 d-flex flex-column" id="app-calendar-sidebar">
                                                <!-- overflow-hidden -->
                                                <div class="sidebar-wrapper">
                                                    <div class="card-body d-flex justify-content-center">
                                                        <button type="button"
                                                                class="btn btn-gradient-primary float-end w-100"
                                                                @click="addDep">
                                                            <span class="align-middle">Add Event</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="mt-auto">
                                                    <img :src="calImg" alt="Calendar illustration" class="img-fluid" />
                                                </div>
                                            </div>
                                            <!-- /Sidebar -->

                                            <!-- Calendar -->
                                            <div class="col-8 position-relative">
                                                <div class="card shadow-none border-0 mb-0 rounded-0">
                                                    <div class="card-body pb-0 app">
                                                        <div class="field text-end">
                                                            <label class="label">Period UOM</label>
                                                            <div class="control">
                                                                <div class="select text-end d-flex">
                                                                    <div class="col-6">&nbsp;</div>
                                                                    <div class="col-6 mb-1">
                                                                        <select v-model="displayPeriodUom" class="form-control w-100">
                                                                            <option>month</option>
                                                                            <option>week</option>
                                                                            <option>year</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- @click-item="onClickItem($event, 1)"
                                                            @item-mouseenter="onMouseEnter"
                                                            @item-mouseleave="onMouseLeave" -->
                                                        <calendar-view
                                                            :items="items"
                                                            currentPeriodLabel="Today"
                                                            :display-period-uom="displayPeriodUom"
                                                            :show-date="showDate"
                                                            doEmitItemMouseEvents="true"
                                                            class="theme-default holiday-us-traditional holiday-us-official">
                                                            <template #header="{ headerProps }">
                                                                <calendar-view-header
                                                                    :header-props="headerProps"
                                                                    @input="setShowDate"
                                                                />
                                                            </template>
                                                        </calendar-view>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /Calendar -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
      </div>
    </div>
    <teleport to="#modal">
        <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in" :class="isShow" :style="{display: isDisplay}">
        <div class="modal-dialog">
            <form
            class="add-new-user modal-content pt-0"
            method="post"
            @submit.prevent="submitForm"
            >
            <input type="hidden" name="id" :v-modal="id" />
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="[isShow='', isDisplay='none']"
            >
                ×
            </button>
            <div class="modal-header mb-1">
                <h4 class="modal-title" id="exampleModalLabel">
                {{ modalShow }} Festival
                </h4>
            </div>
            <div class="modal-body flex-grow-1">
                <div class="mb-1">
                    <label class="form-label" for="basic-addon-name"
                        >Festival Name</label
                    >
                    <input
                        type="text"
                        class="form-control"
                        name="dep_name"
                        v-model="name"
                        placeholder="Festival Name"
                        @keyup="
                        name.length > 0
                            ? [(nameError = 'none'), (this.disabled = false)]
                            : (nameError = 'block')
                        "
                    />
                    <div
                        class="invalid-feedback fw-bold fst-italic"
                        :style="{ display: nameError }"
                    >
                        Please enter Festival name.
                    </div>
                </div>
                <div class="mb-1">
                    <label class="form-label" for="basic-addon-name">Price</label>
                    <input
                        type="number" class="form-control" name="price" v-model="price" id="price" step="any" placeholder="Price" @keyup="
                        price.toString().length > 0
                            ? [(priceError = 'none'), (disabled = false)]
                            : (priceError = 'block')
                        "/>
                    <div class="invalid-feedback fw-bold fst-italic" :style="{display: priceError}" > Please enter price. </div>
                </div>
                <div class="mb-1">
                    <label class="form-label" for="basic-addon-name">Date</label>
                    <Datepicker 
                        type="date"
                        :min="new Date().toISOString().substr(0, 10)"
                        v-model="rangeDate" 
                        format="dd-MM-yyyy"
                        value-format="dd-MM-yyyy"
                        :min-date='new Date()'
                        modelAuto range multiCalendars multiCalendarsSolo />
                    <div class="invalid-feedback fw-bold fst-italic" :style="{display: dateError}" > Please select Date. </div>
                </div>
                <button
                type="submit"
                class="btn btn-primary me-1 data-submit"
                :disabled="disabled"
                >
                Submit 
                </button>
                <button
                type="reset"
                class="btn btn-outline-secondary"
                :disabled="disabled"
                data-bs-dismiss="modal"
                @click="[isShow='', isDisplay='none']"
                >
                Cancel
                </button>
            </div>
            </form>
        </div>
        </div>
    </teleport>
  </template>
  
  <script>
  import { CalendarView, CalendarViewHeader } from "vue-simple-calendar"
  import "../../../node_modules/vue-simple-calendar/dist/style.css"
  // The next two lines are optional themes
  import "../../../node_modules/vue-simple-calendar/dist/css/default.css"
  import "../../../node_modules/vue-simple-calendar/dist/css/holidays-us.css"
  import calImg from '../../assets/images/calendar-illustration.png';
  import { ref } from 'vue'
  import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  import axios from "axios";

  export default {
    name: "Festival",
    components: {
        CalendarView,
        CalendarViewHeader,
        Datepicker
    },
    data() {
      return {
        isShow: "",
        isDisplay: "none",
        name:null,
        nameError: "none",
        price:null,
        priceError: "none",
        rangeDate:ref(),
        dateError: "none",
        modalName: "Add",
        calImg: calImg,
        showDate: new Date(),
        message: "",
        startingDayOfWeek: 0,
        disablePast: true,
        disableFuture: true,
        displayPeriodUom: "month",
        displayPeriodCount: 1,
        displayWeekNumbers: false,
        showTimes: true,
        selectionStart: null,
        selectionEnd: null,
        newItemTitle: "",
        newItemStartDate: "",
        newItemEndDate: "",
        useDefaultTheme: true,
        useTodayIcons: true,
        items: [],
        selection: [{id: 1, text:'month'}, {id:2, text:'week'}, {id:3, text:'year'}],
        response: "",
        token: localStorage.getItem('auth_token'),
        isOpen: false,
        header: [],
        list: [],
        paginationData: {},
        search: null,
      };
    },
    // computed: {
	// 	userLocale() {
	// 		return CalendarMath.getDefaultBrowserLocale
	// 	},
	// 	dayNames() {
	// 		return CalendarMath.getFormattedWeekdayNames(this.userLocale, "long", 0)
	// 	},
	// 	themeClasses() {
	// 		return {
	// 			"theme-default": this.useDefaultTheme,
	// 			"holiday-us-traditional": this.useHolidayTheme,
	// 			"holiday-us-official": this.useHolidayTheme,
	// 		}
	// 	},
	// 	myDateClasses() {
	// 		// This was added to demonstrate the dateClasses prop. Note in particular that the
	// 		// keys of the object are `yyyy-mm-dd` ISO date strings (not dates), and the values
	// 		// for those keys are strings or string arrays. Keep in mind that your CSS to style these
	// 		// may need to be fairly specific to make it override your theme's styles. See the
	// 		// CSS at the bottom of this component to see how these are styled.
	// 		const o = {}
	// 		const theFirst = this.thisMonth(1)
	// 		const ides = [2, 4, 6, 9].includes(theFirst.getMonth()) ? 15 : 13
	// 		const idesDate = this.thisMonth(ides)
	// 		o[CalendarMath.isoYearMonthDay(idesDate)] = "ides"
	// 		o[CalendarMath.isoYearMonthDay(this.thisMonth(21))] = ["do-you-remember", "the-21st"]
	// 		return o
	// 	},
	// },
    async mounted() {
        this.header = [
            "Sr. no.",
            "Festival Name",
            "Date",
            "Price",
            "Created Date",
            "Updated Date",
            "Action",
        ];
        this.getResults();
        this.getListResults();
    },  
    methods: {
        async getResults() {
            this.isShow = ""
            this.isDisplay = "none"
            await axios.get(process.env.VUE_APP_API_URL+"/festival_master", 
            {
                headers: {
                    "Content-Type": "application/json",
                    'Accept': "application/json",
                    "Authorization": `Bearer ${this.token}`
                }
            })
            .then((response) => {
                this.items = response.data.data;
            });
        },
        async getListResults(page = 1) {
            this.isShow = ""
            this.isDisplay = "none"
            await axios.get(process.env.VUE_APP_API_URL+"/festival_master?page=" + page, 
            {
                headers: {
                    "Content-Type": "application/json",
                    'Accept': "application/json",
                    "Authorization": `Bearer ${this.token}`
                }
            })
            .then((response) => {
                this.paginationData = response.data.data;
            });
            this.list = this.paginationData.data;
        },
        async addDep() {
            (this.id = null), (this.name = ""), (this.price = "");
            this.rangeDate = null;
            this.isShow = "show"
            this.isDisplay = "block"
        },
        // periodChanged() {
		// 	// range, eventSource) {
		// 	// Demo does nothing with this information, just including the method to demonstrate how
		// 	// you can listen for changes to the displayed range and react to them (by loading items, etc.)
		// 	// console.log(eventSource)
		// 	// console.log(range)
		// },
		// thisMonth(d/*, h, m*/) {
		// 	// const t = new Date()
        //     return moment(d).format('YYYY-MM-DD');
		// 	// return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
		// },
		// onClickDay(d) {
		// 	this.selectionStart = null
		// 	this.selectionEnd = null
		// 	this.message = `You clicked: ${d}`
        //     console.log(this.message)
		// },
		async onClickItem(id) {
            this.modalName = "Edit";
            this.isShow = "show"
            this.isDisplay = "block"
            const output = await axios.get(process.env.VUE_APP_API_URL+"/festival_master" + (id ? "/" + id : ""),
            {
                headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
                },
            });
            this.id = output.data.data?.id;
            this.name = output.data.data?.name;
            this.price = output.data.data?.price;
            this.rangeDate = [output.data.data.startDate, output.data.data.endDate];
        
		},
        // onMouseEnter(e) {
        //     const childElements = document.querySelectorAll('.cv-item');
        //     childElements.forEach(childElement => {
        //         var delete_id = childElement.getElementsByTagName('span')[0].id
        //         if(e.id == delete_id) {
        //             const element = document.getElementById(e.id)
        //             element.classList.remove("d-none");
        //             element.addEventListener('click', ev => {
        //                 this.onClickItem(ev, 0);
        //                 ev.preventDefault();
        //                 this.isShow = ""
        //                 this.isDisplay = "none"
        //                 axios.delete(process.env.VUE_APP_API_URL+"/festival_master/" + delete_id,
        //                     {
        //                         headers: {
        //                         "Content-Type": "application/json",
        //                         "Accept": "application/json",
        //                         "Authorization": `Bearer ${this.token}`
        //                         },
        //                     }
        //                 )
        //                 .then((response) => {
        //                     this.onClickItem(ev, 0);
        //                     if (response.data.status) {
        //                         this.$toast.success(response.data.message);
        //                         this.disabled = false;
        //                         this.isShow = ""
        //                         this.isDisplay = "none"
        //                         this.getResults();
        //                     } else {
        //                         this.$toast.error(response.data.error?.[0]);
        //                         this.disabled = false;
        //                     }
        //                 })
        //                 .catch((error) => {
        //                     this.$toast.error(error);
        //                     this.disabled = false;
        //                 });
        //             });
        //         }
        //     });
        // },
        // onMouseLeave(e) {
        //     const childElements = document.querySelectorAll('.cv-item');
        //     childElements.forEach(childElement => {
        //         if(e.id == childElement.getElementsByTagName('span')[0].id) {
        //             const element = document.getElementById(e.id)
        //             element.classList.add("d-none");
        //         }
        //     });
        // },
		setShowDate(d) {
			this.message = `Changing calendar view to ${d.toLocaleDateString()}`
			this.showDate = d
            console.log(this.showDate)
		},
		// setSelection(dateRange) {
		// 	this.selectionEnd = dateRange[1]
		// 	this.selectionStart = dateRange[0]
		// },
		// finishSelection(dateRange) {
		// 	this.setSelection(dateRange)
		// 	this.message = `You selected: ${this.selectionStart.toLocaleDateString()} -${this.selectionEnd.toLocaleDateString()}`
		// },
		// onDrop(item, date) {
		// 	this.message = `You dropped ${item.id} on ${date.toLocaleDateString()}`
		// 	// Determine the delta between the old start date and the date chosen,
		// 	// and apply that delta to both the start and end date to move the item.
		// 	const eLength = CalendarMath.dayDiff(item.startDate, date)
		// 	item.originalItem.startDate = CalendarMath.addDays(item.startDate, eLength)
		// 	item.originalItem.endDate = CalendarMath.addDays(item.endDate, eLength)
		// },
		// clickTestAddItem() {
		// 	this.items.push({
		// 		startDate: this.newItemStartDate,
		// 		endDate: this.newItemEndDate,
		// 		title: this.newItemTitle,
		// 		id: "e" + Math.random().toString(36).substr(2, 10),
		// 	})
		// 	this.message = "You added a calendar item!"
		// },
        async submitForm() {
            this.disabled = true;
            if (this.name.length === 0) {
                this.nameError = "block";
            } else if (this.price.length === 0) {
                this.priceError = "block";
            } else {
                if(this.id) {
                    await axios
                        .put(process.env.VUE_APP_API_URL+"/festival_master/" + this.id,
                        {
                            name: this.name,
                            price: this.price,
                            date: this.rangeDate,
                        },
                        {
                            headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": `Bearer ${this.token}`
                            },
                        }
                    )
                    .then((response) => {
                        if (response.data.status) {
                            this.$toast.success(response.data.message);
                            this.disabled = false;
                            this.isShow = ""
                            this.isDisplay = "none"
                            this.getResults();
                            this.getListResults();
                        } else {
                            this.$toast.error(response.data.error?.[0]);
                            this.disabled = false;
                        }
                    })
                    .catch((error) => {
                        this.$toast.error(error);
                        this.disabled = false;
                    });
                } else {
                    await axios
                        .post(process.env.VUE_APP_API_URL+"/festival_master",
                            {
                                name: this.name,
                                price: this.price,
                                date: this.rangeDate,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    "Accept": "application/json",
                                    "Authorization": `Bearer ${this.token}`
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.status) {
                                this.$toast.success(response.data.message);
                                this.disabled = false;
                                this.isShow = ""
                                this.isDisplay = "none"
                                this.getResults();
                                this.getListResults();
                            } else {
                                this.$toast.error(response.data.error?.[0]);
                                this.disabled = false;
                            }
                        })
                        .catch((error) => {
                            this.$toast.error(error);
                            this.disabled = false;
                        });
                    }
                }
        },
        deleteDep(id) {
            this.$swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ms-1",
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                axios.delete(process.env.VUE_APP_API_URL+"/festival_master/" + id,
                    {
                        headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": `Bearer ${this.token}`
                        },
                    }
                    )
                    .then(response => {
                        this.getResults();
                        this.getListResults();
                        if (response.data.status) {
                        this.$toast.success(response.data.message);
                        } else {
                        this.$toast.error(response.data.error?.[0]);
                        }
                    })
                    .catch((error) => {
                    this.$toast.error(error);
                    });
                }
            });
        },
        async searchData() {
            await axios
                .post(process.env.VUE_APP_API_URL+"/festival_master/search",
                {
                    search: this.search,
                },
                {
                    headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                    },
                }
                )
                .then((response) => {
                if (response.data.status) {
                    this.list = response.data.data.data;
                    this.isShow = ""
                    this.isDisplay = "none"
                } else {
                    this.$toast.error(response.data.error?.[0]);
                }
                })
                .catch((error) => {
                    this.$toast.error(error);
                });
        },
    },
  };
  </script>
  <style>
  .app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		color: #2c3e50;
		/* height: 65vh; */
		width: 60vw;
		margin-left: auto;
		margin-right: auto;
	}
    /* .cv-header, .cv-header-nav, .cv-header-days div {
        background-color: rgba(173, 167, 244, 0.2) !important;
        color: #5e5873 !important;
        font-size: 1.45rem !important;
        border: none;
    } */
    .cv-header, .cv-header-nav, .cv-header-days div {
        background-color: rgb(220 241 190 / 20%) !important;
        color: #5e5873 !important;
        font-size: 1.45rem !important;
        border: none;
    }
    .cv-header, .cv-header-nav {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }   
    .cv-header-nav button {
        background-color: transparent !important;
        color: #5e5873 !important;
        font-size: 1.3rem !important;
        border: none;
    }
    .cv-header-days, .cv-header-day, .cv-weeks, .cv-week, .cv-day, .cv-item {
        border-color:  rgba(115, 103, 240, 0.2) !important;
    }
    .cv-weeks {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .cv-wrapper {
        box-shadow: 0 8px 25px -8px #DCF1BE;
        /* box-shadow: 0 8px 25px -8px #9693bd; */
        height: 65vh;
    }
    .cv-item {
        /* width: 100px;
        height: 100px; */
        /* background-color: #f3f3f3;
        margin-top: 10px;
        padding: 10px; */
    }
    .setting li.nav-item:hover {
        background-color: rgb(125 167 65 / 7%) !important;
        box-shadow: 0 8px 10px -8px #7DA741;
        /* box-shadow: 0 8px 10px -8px #958cf1; */
    }

    .theme-default .cv-header, .theme-default .cv-header-day {
        background: linear-gradient(97deg, #7DA741 62.6%, #DCF1BE 135.36%);
    }

    .theme-default .cv-header button {
        color: #fff !important;
    }

    .periodLabel {
        color: #fff;
    }

    .cv-header-days div {
        color: #fff !important;
    }
  </style>
